import {Controller} from "stimulus"
import $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'

import {set_locale} from './localize_controller'
import sessionStorage_controller, {setSessionVar, checkSessionVar, checkSessionVarWithData} from './sessionStorage_controller'
import {get_modalities} from './rentaContractModalityTariff_controller'
import {validate, validate_email} from "./validate_controller";
import {blockBody, blockElementsById, unBlockElementById} from "./blockUI_controller";
import {check_time_reservation} from "./alert_controller";
import {get_popup} from "./popup_controller";
import {get_authenticated_user_data, verification, verify_user} from "./verificaciones_controller";
import {confirm_cart, loaded} from "./swal_controller";
import bootbox from "bootbox";
// VARIABLES GLOBALES
let product_array = {};
let product_description_array = {};

export let create_id_for_product = (category_id, dealer_code, category_code, modality_code, pax) => new Promise ((resolve, reject) => {
    create_array_for_product ('id', btoa (`${category_id}_${dealer_code}_${category_code}_${modality_code}_${pax}`))
    resolve (true);
})
export let create_array_for_product = (key, value, action = 'add', type = null) => {
    let result = product_array
    switch (action) {
        case 'add':
            product_array[key] = value;
            break;
        case 'delete':
            delete product_array[key];
            break;
        case 'clear':
            product_array = {};
            break;
        case 'show':
            result = product_array[key];
            break;

    }

    return result;
}
export let create_array_decription_for_product = (key, value, action = 'add', type = null) => {
    let result = product_array
    switch (action) {
        case 'add':
            product_array[key] = value;
            break;
        case 'delete':
            delete product_array[key];
            break;
        case 'clear':
            product_array = {};
            break;
        case 'show':
            result = product_array[key];
            break;

    }

    return result;
}

export let set_var_session = (name, data, filter = '', action = 'add', index = 0, is_date = false, save = false, save_data_no_filter = false) => new Promise ((resolve, reject) => {

    checkSessionVarWithData (name).then (
        variable => {
            // VERIFICAMOS SI LA VARIABLE EXISTE EN EL SERVIDOR DE NOS ER ASI CONSIDERAMOS QUE ES ELPRIMER PRODUCTO DEL CARRITO Y PROCEDEMOS A CREARLO
            if (variable.success) {
                let filter_data = variable.data;
                let nuevo = true;
                let count_modify = 0;
                //
                if (filter_data[filter].hasOwnProperty (index)) {
                    if (filter_data[filter][index].id === data[filter][0].id) {
                        nuevo = false;

                        if (action === 'erase_product') {
                            delete filter_data[filter][index];
                        } else {
                            //                     //MODIFICAR EN CASO DE QUE SEA IGUAL AL ALMACENADO ENLA SeSSION
                            for (let i in filter_data[filter][index]) {
                                if (filter_data[filter][index].hasOwnProperty (i) && data[filter][0].hasOwnProperty (i)) {
                                    filter_data[filter][index][i] = data[filter][0][i];
                                } else {
                                    // CONTAR LA CANTIDAD DE MODIFICACIONES QUE HAY QUE HACER AL ARRAY ORIGINAL
                                    count_modify += 1;
                                }
                            }
                        }
                    }
                }

                if (nuevo) {
                    filter_data[filter][Object.entries (filter_data[filter]).length] = data[filter][0];
                } else {
                    if (count_modify > 0) {
                        for (let key in data[filter][0]) {
                            if (key !== 'id') {
                                filter_data[filter][index][key] = data[filter][0][key]
                            }
                        }

                    }
                }

                let badge = $ ('.badge-indicator');
                badge.map (elem => {
                    badge[elem].innerText = Object.entries (filter_data[filter]).length;
                })

                setSessionVar ('cart_count_product', Object.entries (filter_data[filter]).length, 'add', false).then ()

                if (Object.entries (filter_data[filter]).length === 0) {
                    setSessionVar (name, filter_data, 'delete', is_date, save).then (
                        result => {
                            cancel_to_shopping_cart ().then ();
                        }
                    )
                } else {
                    let save_data = filter_data;
                    if (save_data_no_filter) {
                        save_data[filter][index] = data[filter][0]
                    }


                    let filtered_data = filter_data[filter];
                    if (action !== 'erase_product') {
                        filtered_data = filter_data[filter][index]
                    }
                    setSessionVar (name, save_data, 'add', is_date, save).then (
                        result => {

                            resolve ({
                                success: true,
                                data: result,
                                filter: filtered_data
                            })
                        }
                    )
                }


            } else {
                setSessionVar (name, data, action, is_date, save).then (
                    result => {
                        resolve ({
                            success: true,
                            data: result,
                            filter: ''
                        })
                    }
                )
            }
        }
    )
})


function sessionVarsForShoppingCart () {

    let sell_type = '';
    if (document.getElementById ('sell_type') !== null && document.getElementById ('sell_type') !== undefined) {
        sell_type = document.getElementById ('sell_type').getAttribute ('data-type');
    } else {
        sell_type = 'b2b';
    }

    let booking = false;
    if (sell_type === 'b2c') {
        booking = true;
    }

    sessionStorage.setItem ('add_product_url', '');
    sessionStorage.setItem ('modify_product_url', '');
    sessionStorage.setItem ('comment_shopping', `Creado desde la Web de Ventas Online ${location.origin}/${sessionStorage.getItem ('locale')}`);
    sessionStorage.setItem ('immediate_payment', 'yes');
    sessionStorage.setItem ('one_item', 'no');
    sessionStorage.setItem ('customer_notify', 'no');
    sessionStorage.setItem ('locale', sessionStorage.getItem ('locale'));
    sessionStorage.setItem ('only_booking', booking);
    sessionStorage.setItem ('success_url', location.origin + `/${sessionStorage.getItem ('locale')}/payment/success`);
    sessionStorage.setItem ('return_url', location.origin + `/${sessionStorage.getItem ('locale')}/payment/return`);
    sessionStorage.setItem ('error_url', location.origin + `/${sessionStorage.getItem ('locale')}/payment/error`);
    sessionStorage.setItem ('cancel_url', location.origin + `/${sessionStorage.getItem ('locale')}/payment/cancel`);
}

let set_reservation_cart_time = async () => {
    let date = new Date ();
    let save_date = date.setMinutes (date.getMinutes () + 8);
    let reservation = await setSessionVar ('reservation_time', save_date, 'add', false);
    return !!reservation.success;
}
let create_shopping_cart = (box_block, product_type) => new Promise ((resolve, reject) => {
    sessionVarsForShoppingCart ();
    setTimeout (function () {
        let data_send = {
            seller: sessionStorage.getItem ('seller'),
            one_item: sessionStorage.getItem ('one_item'),
            immediate_payment: sessionStorage.getItem ('immediate_payment'),
            customer_notify: sessionStorage.getItem ('customer_notify'),
            comment: sessionStorage.getItem ('comment_shopping'),
            locale: sessionStorage.getItem ('locale'),
            add_product_url: sessionStorage.getItem ('add_product_url'),
            modify_product_url: sessionStorage.getItem ('modify_product_url'),
            success_url: sessionStorage.getItem ('success_url'),
            return_url: sessionStorage.getItem ('return_url'),
            error_url: sessionStorage.getItem ('error_url'),
            cancel_url: sessionStorage.getItem ('cancel_url'),
        }

        $.ajax ({
            url: `${location.origin}/${sessionStorage.getItem ('locale')}/create_shopping_car`,
            data: data_send,
            type: "GET",
            beforeSend: function () {
                blockElementsById (box_block, `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t ('global.main.form.message.create_shooping_cart')}</span></div> `, 'rgba(0,0,0,0)', '#ffffff', 'rgba(0,0,0,.6)');
            },
            success: function (data) {
                if (data.success) {
                    let shopping_cart_data = data.data;
                    if (shopping_cart_data.status === 'OK') {
                        setSessionVar ('has_reservation', 'true', 'add', false).then (
                            has_resrevation => {
                                set_reservation_cart_time ().then (
                                    time => {
                                        setSessionVar ('cart_count_product', 0, 'add', false).then ();
                                        setSessionVar ('inPayProccess', 'false', 'add', false).then ();
                                        check_time_reservation ('especial');
                                    }
                                )
                            });

                        setSessionVar ('shopping_cart_code', shopping_cart_data.code, 'add', false).then ()
                        setSessionVar ('shopping_cart_reference', shopping_cart_data.reference, 'add', false).then ()
                        setSessionVar ('payment_url', shopping_cart_data.payment_url, 'add', false).then (
                            result => {
                                payment_type ()

                                resolve ({
                                    next: true
                                })
                            }
                        )


                    } else {
                        toastr.error (shopping_cart_data.errors, shopping_cart_data.code);
                        unBlockElementById (box_block);
                    }
                } else {
                    toastr.error (data.data, data.code);
                    unBlockElementById (box_block);
                }
            }
        })

    }, 500);
})

let set_product_array = (for_direct, index = 0) => new Promise ((resolve, reject) => {
    // seteando el objeto en la variable global
    if (for_direct) {
        product_array = create_array_for_product ('', '', 'all');

        resolve ({
            next: true
        })
    } else {
        //CREANDO VARIABLES PARA ENVIAR AL SErVICIO DE GUARDAR DATOS EN LA SESSION
        checkSessionVarWithData ('cart_object').then (
            cart_object_saved => {
                product_array = cart_object_saved.data.cart_product[index];
                for (const p_array in product_array) {
                    create_array_for_product (p_array, product_array[p_array], 'add')
                }

                resolve ({
                    next: true
                })
            })
    }
})
let create_product = (box_block = null, dealer = null,  modality = null,  index = 0, amount = 1, for_direct = false) => new Promise ((resolve, reject) => {


    set_product_array (for_direct, index).then (
        set_product_array_result => {
            let body_ajax_data = {};
            switch (product_array.product_type) {
                case 'car':
                case 'rex_car':
                case 'bike':
                    body_ajax_data = {
                        shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                        dealer: dealer || create_array_for_product ('dealer', '', 'show'),
                        from_date: create_array_for_product ('date_collect', '', 'show'),
                        to_date: create_array_for_product ('date_delivery', '', 'show'),
                        amount: create_array_for_product ('amount', '', 'show') || 1,
                        modality: modality || create_array_for_product ('modality_code', '', 'show'),
                        product: create_array_for_product ('category', '', 'show'),
                        pick_up_location: create_array_for_product ('pick_up_place', '', 'show'),
                        drop_off_location: create_array_for_product ('drop_off_place', '', 'show'),
                        pick_up_time: create_array_for_product ('time_collect', '', 'show'),
                        drop_off_time: create_array_for_product ('time_delivery', '', 'show'),
                        seller: sessionStorage.getItem ('seller'),
                        drivers: create_array_for_product ('drivers', '', 'show') || 0,
                        supplement_insurance: create_array_for_product ('supplement_insurance', '', 'show'),
                    };
                    break;

                case 'hotel':
                    body_ajax_data = {
                        shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                        dealer: dealer || create_array_for_product ('dealer', '', 'show'),
                        from_date: create_array_for_product ('date_collect', '', 'show'),
                        to_date: create_array_for_product ('date_delivery', '', 'show'),
                        amount: create_array_for_product ('amount', '', 'show') || 1,
                        modality: modality || create_array_for_product ('modality_code', '', 'show'),
                        product: create_array_for_product ('category', '', 'show'),
                        pick_up_location: create_array_for_product ('pick_up_place', '', 'show'),
                        drop_off_location: create_array_for_product ('drop_off_place', '', 'show'),
                        seller: sessionStorage.getItem ('seller'),
                        adult: create_array_for_product ('adult', '', 'show'),
                        children: create_array_for_product ('children', '', 'show'),
                        plan: 1, //PUEDEN IR OTROS COMO 1-CP  2-MAP  3-AP  4-AI
                    };
                    break;
                case 'bus_shuttle':
                case 'bus_tour':
                case 'bus':
                    body_ajax_data = {
                        shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                        dealer: dealer || create_array_for_product ('dealer', '', 'show'),
                        from_date: create_array_for_product ('date_collect', '', 'show'),
                        to_date: create_array_for_product ('date_delivery', '', 'show'),
                        amount: amount,
                        modality: modality || create_array_for_product ('modality_code', '', 'show'),
                        product: create_array_for_product ('category', '', 'show'),
                        seller: sessionStorage.getItem ('seller'),
                        comment: create_array_for_product ('comment', '', 'show'),
                       };
                    break;

                case 'catering_service':
                    body_ajax_data = {
                        shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                        dealer: dealer || create_array_for_product ('dealer', '', 'show'),
                        from_date: create_array_for_product ('date_collect', '', 'show'),
                        to_date: create_array_for_product ('date_delivery', '', 'show'),
                        amount: create_array_for_product ('amount', '', 'show') || 1,
                        modality: modality || create_array_for_product ('modality_code', '', 'show'),
                        product: create_array_for_product ('category', '', 'show'),
                        seller: sessionStorage.getItem ('seller'),
                         comment: `Reservación para ${product_array.text_destination}, ${product_array.text_category} a las ${product_array.time_collect} del día ${product_array.date_collect}`
                    };
                    break;
            }

            $.ajax ({
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/product_shopping_car`,
                data: body_ajax_data,
                type: "GET",

                beforeSend: function () {
                    if (box_block !== null) {
                        blockElementsById (box_block, `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t ('global.main.form.message.checking_availability')}</span></div> `, 'rgba(0,0,0,0)', '#ffffff', 'rgba(0,0,0,.6)');
                    }
                },
                success: function (data) {
                    if (data.success) {
                        if (data.data.status.toLowerCase () === 'ok') {

                            let total_price = $ ('#total_price');
                            let total_price_conversion = $ ('#total_price_conversion');
                            if (total_price.length > 0) {
                                total_price[0].setAttribute ('data-price', data.data.amount);
                            }
                            if (total_price_conversion.length > 0) {
                                total_price_conversion[0].setAttribute ('data-price', data.data.sale_amount);
                            }


                            let price = data.data.details.products[index].price;
                            let sale_price = data.data.details.products[index].sale_price;
                            let split = price.split (' ');
                            let split_sale = sale_price.split (' ');
                            if (data.data.items > 0) {
                                create_array_for_product ('price', split[0].replace(',',''));
                                create_array_for_product ('text_price', price);
                                create_array_for_product ('item_id', data.data.item_id);
                                create_array_for_product ('price_conversion', split_sale[0].replace(',',''));
                                create_array_for_product ('text_price_conversion', sale_price);


                                //Actualizando los datos en la variable de session una vez añadido el producto
                                let value = {
                                    cart_product: [
                                        create_array_for_product ('', '', 'all')
                                    ]
                                }

                                set_var_session ('cart_object', value, 'cart_product', 'add', index, false, false, true).then (
                                    cart_object_saved => {
                                        if (cart_object_saved.success) {
                                            show_product ('card_product', true).then (
                                                show => {
                                                    set_reservation_cart_time ().then (
                                                        time => {
                                                            check_time_reservation ('especial');
                                                            product_array = {};
                                                            setSessionVar ('has_reservation', 'true', 'add', false);

                                                            resolve ({
                                                                next: true
                                                            })
                                                        }
                                                    )
                                                }
                                            );
                                        } else {
                                            toastr.error ('No se pudo almacenar la pre-reserva', 'Carrito de compra')
                                        }
                                    }
                                )

                            } else {
                            }

                        } else {
                            console.log ('TRATAMIENTO PARA CUANDO EL SERVIDOR RESPONDE FALSE');
                            console.log (data);
                            toastr.error (data.data.errors.str, data.data.status);

                            blockElementsById (box_block, `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t ('global.main.form.message.no_availability')}</span></div> `, 'rgba(0,0,0,0)', '#ffffff', 'rgba(0,0,0,.6)');

                            setTimeout (function () {
                                unBlockElementById (box_block);
                            }, 3000)
                        }
                    } else {
                        console.log (data);
                    }

                }
            })
        }
    )
})

let add_product_to_shopping_cart = (box_block, index = 0, id, dealer, product, amount, type, hasModality) => new Promise ((resolve, reject) => {
    get_modalities (box_block, dealer,'code', product, type, hasModality, true).then (
        modality => {
            if (modality.next) {
                create_product (box_block, dealer, modality.data.code, index, amount).then (
                    product => {
                        if (product.next) {
                            get_total_price ();
                            resolve ({
                                next: true
                            })
                        }
                    }
                )
            }
        }
    )
})

let add_direct_product_to_cart = (box_block, dealer, product, amount, type, modality_code) => new Promise ((resolve, reject) => {


    let save = true;
    switch (type) {
        case 'car':
        case 'rex_car':
        case 'bike':
            save = false;
            break;
    }

    checkSessionVarWithData ('cart_object').then (
        cart_object => {
            let index = 0;
            if (cart_object.success) {
                index = Object.entries (cart_object.data.cart_product).length
            }

            create_product (box_block, dealer, modality_code, index, amount, true).then (
                product => {
                    if (product.next) {
                        resolve ({
                            next: true
                        })
                    }
                }
            )
        }
    )
})
let verify_product_in_cart = (confirm = false) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/verify_products_in_cart?confirm=${confirm}`,
        data: {},
        type: 'GET',
        success: function (data) {
            if (data.success) {
                resolve ({
                    next: true
                })
            } else {
                toastr.error (data.msg, 'Error');
            }
        }
    })
})
let remove_item_to_shopping_cart = (item) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: 'delete_service_shopping_cart',
        data: {
            shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
            item_id: item
        },
        type: 'GET',
        beforeSend: function () {
            toastr.info ('Eliminando producto del carrito', 'Carrito de compra');
        },
        success: function (data) {
            toastr.success ('Producto eliminado');
            resolve ({
                code: data.code,
                success: data.success,
                status: data.data.status,
                amount: data.data.amount,
                items: parseInt (data.data.items)
            })
        }
    })
})

function payment_type (merchant = 'bdi') {
    setTimeout (() => {
        setSessionVar ('merchant', merchant, 'add', false);

        $.ajax ({
            url: `${location.origin}/${sessionStorage.getItem ('locale')}/payment_type`,
            data: {
                shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                merchant: merchant,
            },
            type: "GET",
            beforeSend: function () {
                toastr.info ('Verificando estado de la pasarela');
            },
            success: function (data) {
                if (data.success) {
                    toastr.success ('Pasarela de pago verificada');
                }
            }
        })

        return true;
    }, 500)

}

let check_in_pay_proccess = () => new Promise ((resolve, reject) => {

    if (sessionStorage.hasOwnProperty ('inPayProccess')) {
        if (sessionStorage.getItem ('inPayProccess') === "true") {
            get_popup ('with_rent_in_pay_process').then (
                with_rent_in_pay_process => {
                    Swal.fire ({
                        title: with_rent_in_pay_process.title || 'Proceso de pago',
                        text: with_rent_in_pay_process.description || "Usted tiene un proceso de pago activo, ¿Desea continuar?",
                        icon: with_rent_in_pay_process.icon || 'warning',
                        showCancelButton: true,
                        confirmButtonText: I18n.t ('global.popup.with_rent_in_pay_process_confirm'),
                        cancelButtonText: I18n.t ('global.popup.with_rent_in_pay_process_cancel'),
                        reverseButtons: true,
                        allowOutsideClick: false,
                        backdrop: `rgba(255, 255, 255, 1)`,
                        customClass: {
                            confirmButton: 'btn btn-success mr-2',
                            cancelButton: 'btn btn-secondary mr-2'
                        },
                        buttonsStyling: false
                    }).then ((result) => {
                        if (result.isConfirmed) {
                            location.href = sessionStorage.getItem ('payment_url');
                        } else {
                            //with_rent_in_pay_process_cancel
                            blockBody ();
                            set_locale ().then (
                                locale => {
                                    setSessionVar ('', '', 'clear', false).then (
                                        session_clear => {
                                            // location.href = `/${locale.locale}`;
                                        }
                                    )
                                }
                            )


                        }
                    })
                }
            )
        } else {
            resolve (true);
        }
    } else {
        resolve (true);
    }
})

function tpl_product (data, index = 0) {
    let input_type = 'number';
    let input_class = '';
    let description_html_product = '';
    let has_modality = !!(data.modality_code);
    let has_tarrifs = !!(data.tariff_code);


    switch (data.product_type) {
        case 'car':
        case 'rex_car':
            input_type = 'hidden';
            input_class = 'ocultar';
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                             <span class="col-md-3">${I18n.t('reservation.side_bar.pickup_place')} : ${data.collect_place_text}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.pickup_date')} : ${data.date_collect} ${data.time_collect}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.dropoff_place')}: ${data.delivery_place_text}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.dropoff_date')}: ${data.date_delivery} ${data.time_delivery}</span>
                            </span>`
            break;
        case 'bike':
            input_type = 'hidden';
            input_class = 'ocultar';
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                             <span class="col-md-3">${I18n.t('reservation.side_bar.pickup_place')} : ${data.collect_place_text}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.pickup_date')} : ${data.date_collect} ${data.time_collect}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.dropoff_place')}: ${data.delivery_place_text}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.dropoff_date')}: ${data.date_delivery} ${data.time_delivery}</span>
                            </span>`
            break;
        case 'hotel':
            input_type = 'hidden';
            input_class = 'ocultar';
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.pickup_date_hotel')} : ${data.date_collect}</span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.dropoff_date_hotel')}: ${data.date_delivery} </span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.room_count')} : ${data.amount} </span>
                            <span class="col-md-3">${I18n.t('reservation.side_bar.room_for',{adult: data.adult, childrens: data.children})}</span>
                            </span>`
            break;
        case 'bus_shuttle':
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                             <span class="col-md-6">${I18n.t('reservation.side_bar.pickup_place')} : ${data.collect_place_text}</span>
                            <span class="col-md-6">${I18n.t('reservation.side_bar.date')} : ${data.date_collect}</span>
                           
                            </span>`
            break;
        case 'bus_tour':
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                             <span class="col-md-6">${I18n.t('reservation.side_bar.pickup_place')} : ${data.collect_place_text}</span>
                            <span class="col-md-6">${I18n.t('reservation.side_bar.date')} : ${data.date_collect}</span>
                           
                            </span>`
            break;
        case 'bus':
            input_type = 'hidden';
            input_class = 'ocultar';
            description_html_product = `<span class="row">
                             <span class="col-md-6">${I18n.t('reservation.side_bar.modality')}: ${data.modalities_name}</span>
                             <span class="col-md-6">${I18n.t('reservation.side_bar.category')}: ${data.text_category}</span>
                             <span class="col-md-6">${I18n.t('reservation.side_bar.date')} : ${data.date_collect}</span>
                             <span class="col-md-12">${I18n.t('reservation.side_bar.pickup_place')} : ${data.comment}</span>
                            
                           
                            </span>`
            break;
        case 'catering_service':
            description_html_product = `<span class="row">
                             <span class="col-md-12">${I18n.t('reservation.side_bar.destiny')}: ${data.text_destination}</span>
                             <span class="col-md-6">${I18n.t('reservation.side_bar.date')} : ${data.date_collect}</span>
                            <span class="col-md-6"> ${I18n.t('main.body.catalog.from')}: ${data.time_collect}</span>
                           
                            </span>`
            break;
    }

    let amount = data.amount || 1;

    let input_price = `<div class="input-group ${input_class} mb-3" style="width: 50% !important;">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">${I18n.t('reservation.side_bar.amount')}</span>
                          </div>
                          <input id="input_price_${index}" type="${input_type}" class="form-control input_evaluate" value="${amount}" style="width: 35px !important;" placeholder="1" data-price="00.00" data-price-conversion="00.00" data-currency="EUR" data-currency-conversion="USD" data-amount="${amount}" data-action="change->preferences#evaluate blur->preferences#evaluate keyup->preferences#evaluate" data-min="${data.day_min_rent || 1}" data-max="${data.day_max_rent || 1}" data-block="add_product_${index}" style="min-width: 100px;">
                        </div>
                        `;
    if (data.hasOwnProperty ('text_price')) {
        let price_array = data.text_price.split (' ');
        let price_x_amount = parseFloat(price_array[0].replace(',','')) * parseFloat (amount);
        let price_conversion_array = data.text_price_conversion.split (' ');

        input_price = `<div class="input-group ${input_class} mb-3" style="width: 50% !important;">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">${I18n.t('reservation.side_bar.amount')}</span>
                          </div>
                          <input id="input_price_${index}" type="${input_type}" class="form-control input_evaluate" value="${amount}" style="width: 35px !important;" data-price="${price_x_amount}" data-price-conversion="${price_conversion_array[0]}" data-currency="${price_array[1]}" data-currency-conversion="${price_conversion_array[1]}" data-amount="${amount}" data-action="change->preferences#evaluate blur->preferences#evaluate keyup->preferences#evaluate" data-min="${data.day_min_rent || 1}" data-max="${data.day_max_rent || 1}" data-block="add_product_${index}" style="min-width: 100px;">
                        </div>
`;
    }

    let add_button = ``;
    let delete_button = `<a href="#" class="a-link btn btn-outline-danger btn-sm remove-product" data-action="b2b#remove_product_for_cart" data-id="${data.id}" data-item="0" data-index="${index}">
                            <i class="fa fa-trash"></i> 
                            <span class="d-none d-md-inline">${I18n.t('reservation.side_bar.delete_product')}</span>
                            <span class="d-inline d-md-none">${I18n.t('reservation.side_bar.delete_product_mobile')}</span> 
                        </a>`;

    if (data.hasOwnProperty ('item_id')) {

        switch (data.product_type) {
            case 'bus_shuttle':
            case 'catering_service':
            case 'bus_tour':
                add_button = `<a id="add_product_${index}" href="#" class="a-link btn btn-outline-warning add-product btn-sm" data-action="b2b#add_product_to_cart" data-button="${data.id}" data-id="${data.id}" data-index="${index}" data-dealer="${data.dealer}" data-product="${data.category}" data-amount="${data.amount}" data-contract="${data.contract_code_number}" data-contract-code="${data.contract_code}" data-product-type="${data.product_type}" data-has-modality="${has_modality}" data-has-tarriff="${has_tarrifs}">
                            <i class="fa fa-refresh"></i> 
                            <span class="d-none d-md-inline">${I18n.t('reservation.side_bar.update_product')}</span>
                            <span class="d-inline d-md-none">${I18n.t('reservation.side_bar.update_product_mobile')}</span> 
                           
                            </a>`;
                break;

            default:
                add_button = `<a id="add_product_${index}" href="#" class="a-link btn btn-outline-secondary add-product btn-sm disabled" data-action="b2b#add_product_to_cart" data-button="${data.id}" data-id="${data.id}" data-index="${index}" data-dealer="${data.dealer}" data-product="${data.category}" data-amount="${data.amount}" data-contract="${data.contract_code_number}" data-contract-code="${data.contract_code}" data-product-type="${data.product_type}" data-has-modality="${has_modality}" data-has-tarriff="${has_tarrifs}">
                            <i class="fa fa-gift"></i> 
                            <span class="d-none d-md-inline">${I18n.t('reservation.side_bar.add_product')}</span>
                            <span class="d-inline d-md-none">${I18n.t('reservation.side_bar.add_product_mobile')}</span> 
                            
                            </a>`;
                break;
        }

        delete_button = `<a href="#" class="a-link btn btn-outline-danger remove-product btn-sm" data-action="b2b#remove_product_for_cart" data-id="${data.id}" data-item="${data.item_id}" data-index="${index}" >
                            <i class="fa fa-trash"></i> 
                            <span class="d-none d-md-inline">${I18n.t('reservation.side_bar.delete_product')}</span>
                            <span class="d-inline d-md-none">${I18n.t('reservation.side_bar.delete_product_mobile')}</span>
                        </a>`;
    } else {
        add_button = `<a id="add_product_${index}" href="#" class="a-link btn btn-outline-success add-product btn-sm" data-action="b2b#add_product_to_cart" data-button="${data.id}" data-id="${data.id}" data-index="${index}" data-dealer="${data.dealer}" data-product="${data.category}" data-amount="${data.amount}" data-contract="${data.contract_code_number}" data-contract-code="${data.contract_code}" data-product-type="${data.product_type}" data-has-modality="${has_modality}" data-has-tarriff="${has_tarrifs}">
                            <i class="fa fa-gift"></i> 
                            <span class="d-none d-md-inline">${I18n.t('reservation.side_bar.add_product')}</span>
                            <span class="d-inline d-md-none">${I18n.t('reservation.side_bar.add_product_mobile')}</span> 
                            </a>`;
    }

    return `
            <div id="ibox_${index}" class="ibox-content">
              <div class="row shoping-cart-table">
                        <div class="col-12 col-md-4 cart-product-imitation d-md-block">
                           <div class="img-container">
                                <img src="${product_description_array[data.id].image}" alt="${data.dealer_name}">
                            </div>
                        </div>
                        <div class="col-12 col-md-8 cart-product-data">
                            <h3>
                                <a href="#" class="text-navy">
                                  ${data.name}
                                </a>
                            </h3>
                            <span class="small expand-info" onclick="$(this).toggleClass('active');">
                                ${description_html_product}
                                <hr>
                                ${product_description_array[data.id].description}                          
                            </span>
                          <div class="m-t-sm mt-2">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    ${input_price}
                                </div>
                                <div class="col-6 col-md-4">${add_button}</div>
                                <div class="col-6 col-md-4">${delete_button}</div>
                            </div>
                          </div>
                        </div>
                    </div>
            </div>
    `;

}

function tpl_table_product (data, index = 0, with_image = false) {
    let price = '';
    let currency = ''
    let amount = data.amount || 1;
    if (data.price !== undefined) {
        price = `<b>Precio:</b> ${(parseFloat(data.price.replace(',',''))* parseInt (amount)).toFixed (2)}`
        currency = data.text_price.split (' ')[1];
    }

    let delete_button = ``;
    if (data.hasOwnProperty ('item_id') && !with_image) {
        delete_button = `<a class="btn btn-outline-danger btn-sm form-control" style="cursor: pointer" data-action="b2b#remove_product_for_cart" data-id="${data.id}" data-item="${data.item_id}" data-index="${index}"><span class="fa fa-trash"  ></a>`;
    }

    let html = `<div id="tr_product_${index}" class="col-12">`
    html += `<div class="row mb-3 px-2">
                <div class="col-md-5">
                <img class="table-image-cart" style="width: 100%; height: 75px;" src="${product_description_array[data.id].image}" alt="${data.dealer_name}">
                </div>
                <div class="col-md-7 p-0 pr-3">
                    <strong style="font-size: .85em">${data.text_category}</strong>
                    <div class="row">
                        <div class="col-12 border-bottom" style="font-size: .85em">${price} ${currency}</div>
                        <div class="col-12" style="font-size: .85em">Cantidad: ${amount}</div>
                        <div class="col-12" style="font-size: .85em">
                          ${delete_button}
                        </div>
                    </div>
                </div>
               
            </div>`
    html += `</div>`
    return html;
}

export let get_shopping_cart_product = (data, with_image = false, div = 'table_resumen_shopping_cart', with_time = false) => new Promise ((resolve, reject) => {
    let html = '';
    if (with_time) {
        html += `<h6 class="dropdown-header small-header">${I18n.t ('reservation.side_bar.time_elapse')}: <span class="time_remaining"></span></h6>`
    }

    html += `<div class="row m-0">`;

    let count_product = 0;
    for (let product in data) {
        html += tpl_table_product (data[product], count_product, with_image);
        count_product += 1;
    }

    html += `</div>`;

    $ (`#${div}`).html (html)

    if (with_time) {
        check_time_reservation ('especial');
    }

    resolve ({
        next: true
    })
})

function get_total_price () {
    let inputs_price = $ ('.input_evaluate');
    let total_price = $ ('#total_price')[0];
    let total_price_conversion = $ ('#total_price_conversion')[0];
    let total = 0;
    let totalConversion = 0;
    let currency = 'EUR';
    let currencyConversion = 'USD';

    if (!total_price.dataset.price.isEmpty ()) {
        total_price.innerText = `${total_price.dataset.price}`;
        if (!total_price_conversion.dataset.price.isEmpty ()) {
            total_price_conversion.innerText = `${total_price_conversion.dataset.price}`;
        }
    } else {
        if (inputs_price.length <= 0) {
            location.href = `${location.origin}/${sessionStorage.getItem ('locale')}`;
        } else {
            if (total_price !== undefined) {
                inputs_price.map ((index, elem) => {
                    let sum_price = elem.dataset.price;
                    if (sum_price.length > 3) {
                        sum_price = sum_price.split (',');
                        sum_price = sum_price.join ('');
                    }

                    total += parseFloat (sum_price);
                    currency = elem.dataset.currency;
                });

                total_price.innerText = `${parseFloat (total).toFixed (2)} ${currency}`;
            }
            if (total_price_conversion !== undefined) {
                inputs_price.map ((index, elem) => {
                    let sum_price_conversion = elem.dataset.priceConversion;

                    if (sum_price_conversion.length > 3) {
                        sum_price_conversion = sum_price_conversion.split (',');
                        sum_price_conversion = sum_price_conversion.join ('');
                    }

                    totalConversion += parseFloat (sum_price_conversion);
                    currencyConversion = elem.dataset.currencyConversion;
                });

                total_price_conversion.innerText = `${parseFloat (totalConversion).toFixed (2)} ${currencyConversion}`;
            }
        }
    }


}

export let show_product = (div = 'card_product', show_table = true) => new Promise ((resolve, reject) => {
    let show_space = $ (`#${div}`);
    if (show_space.length > 0) {
        checkSessionVarWithData ('cart_objects_description').then (
            descriptions_object => {
                if (descriptions_object.success) {
                    product_description_array = descriptions_object.data
                    let show_space = $ (`#${div}`);
                    let html = '';
                    if (show_space !== undefined) {
                        show_space.html (`<div  class="d-flex justify-content-center align-items-center flex-column" style="height: 76vh;
                        background: rgba(255,255,255,.5);
                        backdrop-filter: blur(2px);
                        border-radius: 5px;">
                        <span class="fa fa-spin fa-spinner fa-2x"></span>
                        <small>Cargando productos del carrito</small>
                    </div>`)
                    }

                    checkSessionVarWithData ('cart_object').then (s_cart => {
                        let products = s_cart.data.cart_product;
                        let count_product_box = $ ('#count_product');
                        if (show_space !== undefined) {
                            let count_product = 0
                            for (let product in products) {
                                html += tpl_product (products[product], count_product);
                                count_product += 1;
                            }

                            show_space.html (html);

                            if (count_product_box !== undefined && count_product_box.length > 0) {
                                count_product_box[0].innerText = count_product;
                            }

                        }

                        if (show_table) {
                            get_shopping_cart_product (products).then (
                                res => {
                                    resolve ({
                                        next: res.next
                                    })
                                }
                            )
                        } else {
                            resolve ({
                                next: true
                            })
                        }
                    })
                }

            }
        )
    }else {
        resolve ({
            next: true
        })
    }


})
let get_terms = () => new Promise ((resolve, reject) => {
    $.ajax ({
        url: 'get_terms',
        data: {
            seller: sessionStorage.getItem ('seller')
        },
        type: "GET",
        success: function (data) {
            let dialog_user_data = bootbox.dialog ({
                    title: I18n.t ('global.footer.term_and_conditions'),
                    message: `<div class="d-flex flex-column">
                                    <div class="auto-heigt px-2 text-justify">
                                        ${data.terms}
                                    </div>
                                    <div class="form-inline border-top mt-3 py-4">
                                        <input type="checkbox" id="terms_and_condition_accepted" class="mr-2">
                                        <label for="terms_and_condition_accepted">Acepta nuestros terminos y condiciones</label>
                                    </div>
                                </div>`,
                    size: 'large',
                    buttons: {
                        cancel: {
                            label: "Cancelar",
                            className: 'btn-secondary'
                        },
                        ok: {
                            label: "Aceptar",
                            className: 'btn-success',
                            callback: function (e) {
                                let check = $ ('#terms_and_condition_accepted');
                                if (check.is (':checked')) {
                                    resolve ({
                                        success: true
                                    })
                                } else {
                                    toastr.warning ('Debe acceptar nuestros terminos y condiciones para continuar', 'Términos y condiciones');
                                    return true;
                                }
                            }
                        }
                    }
                })
            ;
        }
    })
})

let confirm_to_shopping_cart = () => new Promise ((resolve, reject) => {
    verify_product_in_cart (true).then (
        verify => {
            if (verify.next) {
                get_authenticated_user_data ().then (
                    user => {
                        get_terms ().then (
                            accept_terms => {
                                if (accept_terms.success) {
                                    let data = {
                                        seller: sessionStorage.getItem ('seller'),
                                        shopping_cart: sessionStorage.getItem ('shopping_cart_code'),
                                        email: user.email,
                                        first_name: user.username,
                                        last_name: user.lastname,
                                        reservation_passport: user.passport,
                                        reservation_country: user.country_code,
                                        reservation_address: user.address,
                                        reservation_city: user.country_city
                                    };


                                    $.ajax ({
                                        url: 'confirm_shopping_car',
                                        data: data,
                                        type: "GET",
                                        beforeSend: function () {
                                            toastr.info ("Verificando datos de compra", 'Carrito de compra');
                                        },
                                        success: function (data) {
                                            if (data.success) {
                                                if (data.data.status !== 'FAIL') {
                                                    toastr.info ('Verificando datos de su compra', 'Carrito de compra');
                                                    let history_data = {
                                                        reserva: data.data,
                                                        user: user,
                                                        cart: sessionStorage.getItem ('shopping_cart_code'),
                                                        seller: sessionStorage.getItem ('seller'),
                                                        merchant: sessionStorage.getItem ('merchant'),
                                                        price: document.getElementById ('total_price').innerText
                                                    }
                                                    create_history_cart (history_data).then (
                                                        result => {
                                                            if (result.success) {
                                                                sessionStorage.setItem ('payment_status', data.data.payment_status);
                                                                sessionStorage.setItem ('payment_url', data.data.payment_url);

                                                                confirm_cart (data.data.payment_url);

                                                            }
                                                        }
                                                    );
                                                } else {
                                                    toastr.error (data.data.errors.str, data.data.errors.code);
                                                }

                                            } else {
                                                setSessionVar ('', '', 'clear', false);
                                                Swal.fire (
                                                    {
                                                        title: data.data.msg || 'Error',
                                                        text: data.data.description || data.msg,
                                                        icon: 'error',
                                                        showCancelButton: false,
                                                        confirmButtonText: I18n.t ('global.popup.create_shopping_cart_confirm'),
                                                        reverseButtons: true,
                                                        allowOutsideClick: false,
                                                        backdrop: `rgba(255, 255, 255, 1)`,
                                                        customClass: {
                                                            confirmButton: 'btn btn-success',
                                                        },
                                                        buttonsStyling: false
                                                    }
                                                ).then (() => {
                                                    blockBody ();
                                                    set_locale ().then (
                                                        locale => {
                                                            location.href = `/${locale.locale}`;
                                                        }
                                                    )

                                                });
                                            }
                                        }
                                    })
                                }
                            }
                        )

                    })
            }
        }
    )


})

export let cancel_to_shopping_cart = () => new Promise ((resolve, reject) => {

    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/cancel_shopping_cart`,
        data: {
            shopping_cart: sessionStorage.getItem ('shopping_cart_code')
        },
        type: 'GET',
        beforeSend: function () {
            toastr.info ('Cancelando carrito de compras', 'Cancelar carrito');
        },
        success: function (data) {
            blockBody ();
            let enable_analytics = sessionStorage.getItem('enable_analytics') || true;
            setSessionVar ('', '', 'clear', false).then (
                clear => {
                    set_locale ().then (
                        locale => {
                            product_array = {};
                            setSessionVar('enable_analytics',enable_analytics,'add',false,).then(
                                save_analitycs => {
                                    location.href = `${location.origin}/${locale.locale}`
                                }
                            )

                        })
                }
            )
        }
    })
})

let create_history_cart = (data_cart) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/create_history`,
        data: {
            code: data_cart.cart,
            seller: data_cart.seller,
            product_reference: data_cart.reserva.reference,
            dealer_name: '',
            payment_url: data_cart.reserva.payment_url,
            vehicle_type: '',
            price: data_cart.price,
            merchant: data_cart.merchant

        },
        type: "GET",
        beforeSend: function () {
            toastr.info ('Generando enlace de pago', 'Carrito de compra');
        },
        success: function (data) {
            resolve (data);
        }
    });

})


export default class extends Controller {

    initialize () {
        checkSessionVarWithData ('cart_objects_description').then (
            descriptions_object => {
                if (descriptions_object.success) {
                    product_description_array = descriptions_object.data
                }

            }
        )

        checkSessionVarWithData ('cart_object').then (
            check_cart_object => {
                if (!check_cart_object.success) {
                    let value = {
                        cart_product: []
                    }

                    setSessionVar ('cart_object', value, 'add', false).then ()
                    setSessionVar ('cart_count_product', 0, 'add', false).then ()
                    $('#show_reservation').css({'display':'none'});

                }else{
                    let show_reservation = $('#show_reservation');
                    let badge = show_reservation.find('span.badge-info');
                    show_reservation.removeAttr('style');
                    badge[0].innerText = Object.keys(check_cart_object.data.cart_product).length
                    setSessionVar ('cart_count_product', Object.keys(check_cart_object.data.cart_product).length, 'add', false).then ()
                }
            }
        )


        let other_products = $ ('#other_product');
        //Buscar otros productos de interes en caso de que exista el div OTHER_PRODUCTS
        if (other_products !== undefined && other_products.length > 0) {
            $.ajax ({
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart/other-products`,
                data: {
                    unique: true
                },
                type: 'GET',

                success: function (data) {
                    let html = ``;

                    if (data.success) {

                        data.data.map ((element, index) => {
                            let description = ''
                            if (element.description!== null && !element.description.isEmpty ()) {
                                description = element.description.slice (5, 100);
                            }

                            html += `<div>
                                  <div class="product_card">
                                    <div class="card-image">
                                      <a href="javascript:;" class="product-name">
                                        <img src="${element.image}" alt="${element.name}">
                                      </a>
                                    </div>
                                    <div class="card-body mt-3 mt-md-0">
                                      <div class="small m-t-xs text-right" >
                                        <span class="font-weight-bold">${element.name} <hr> </span>
                                        <span class="mt-2" style="font-weight: 350;"><p style="text-align: justify;margin-bottom: 0;">${description}...</p></span>
                                        <span style="font-weight: 350;"><b>${I18n.t('main.body.catalog.from')} ${element.price} EUR</b>  </span>
                            
                                        <div class="mt-2">
                                          <a href="${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart/${element.id}/details" class="btn btn-success btn-sm">${I18n.t('global.main.info')}</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>`

                        })
                        other_products.html (html);

                        setTimeout (function () {
                            other_products.slick ({
                                infinite: true,
                                speed: 300,
                                autoplay: true,
                                autoplaySpeed: 3000,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                rows: 1,
                                responsive: [{
                                    breakpoint: 1000,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                                    }
                                }, {
                                    breakpoint: 700,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }]
                            })
                        }, 100)

                    }
                }
            })
        }
    }

    connect () {
        if (location.pathname.includes ('shopping_cart') && !location.pathname.includes ('details')) {
            if ((sessionStorage.getItem ('shopping_cart_code') !== undefined && sessionStorage.getItem ('shopping_cart_code') !== null) && (sessionStorage.getItem ('has_reservation') !== undefined && sessionStorage.getItem ('has_reservation') !== null && sessionStorage.getItem ('has_reservation') === 'true')) {
                check_in_pay_proccess ().then (
                    pay_proccess => {
                        show_product ().then (
                            product => {
                                get_total_price ();
                                check_time_reservation ('especial');
                            }
                        )

                    }
                )


                $ ('.checkout_shopping_cart').on ('click', function (e) {
                    confirm_to_shopping_cart ().then ();
                })

                $ ('.cancel_shopping_cart').on ('click', function (e) {
                    cancel_to_shopping_cart ().then ();
                })

            } else {
                get_popup ('without_rent').then (
                    no_rent => {
                        Swal.fire (
                            {
                                title: no_rent.title || 'Reserva',
                                text: no_rent.description || "Usted no cuenta con ningun proceso de reserva o pago activo",
                                icon: no_rent.icon || 'error',
                                showCancelButton: false,
                                confirmButtonText: I18n.t ('global.popup.without_rent_confirm'),
                                reverseButtons: true,
                                allowOutsideClick: false,
                                backdrop: `rgba(255, 255, 255, 1)`,
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false
                            }
                        ).then (() => {
                            blockBody ();
                            set_locale ().then (
                                locale => {
                                    setSessionVar ('', '', 'clear', false).then (clear => {
                                        location.href = `/${locale.locale}`;
                                    })
                                })
                        });
                    }
                )
            }
        }
    }

    create_reservation (e) {
        let validate_target = e.target.dataset.validate;
        let block_target = e.target.dataset.validate;
        if (e.target.dataset.hasOwnProperty ('block')) {
            block_target = e.target.dataset.block;
        }
        validate (validate_target).then (
            is_valid => {
                if (is_valid.next) {
                    if (create_array_for_product ('product_type', '', 'show') === undefined) {
                        let product_type = $ ('#search_form_type').val ();
                        create_array_for_product ('product_type', product_type);
                    }

                    if (create_array_for_product ('id', '', 'show') === undefined) {
                        create_id_for_product (create_array_for_product ('category_id', '', 'show'), create_array_for_product ('dealer_code', '', 'show'), create_array_for_product ('category_code', '', 'show'), create_array_for_product ('modality_code', '', 'show'), create_array_for_product ('pax', '', 'show')).then ()
                    }

                    if (sessionStorage.hasOwnProperty ('shopping_cart_code') && (sessionStorage.hasOwnProperty ('has_reservation') && sessionStorage.getItem ('has_reservation') === 'true')) {
                        blockElementsById (block_target, `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t ('global.main.form.message.create_shooping_cart')}</span></div> `, 'rgba(0,0,0,0)', '#ffffff', 'rgba(0,0,0,.6)');

                        if (e.target.dataset.addtocart === 'true') {

                            add_direct_product_to_cart (block_target, create_array_for_product ('dealer', '', 'show'), create_array_for_product ('category', '', 'show'), create_array_for_product ('amount', '', 'show'), create_array_for_product ('product_type', '', 'show'), create_array_for_product ('modality_code', '', 'show')).then (
                                product_add => {
                                    if (product_add.next) {
                                        location.href = `${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart`
                                    } else {
                                        toastr.error ('No se pudo añadir el producto', 'Carrito de compra')
                                    }
                                }
                            )
                        } else {
                            // CREANDO VARIABLES PARA ENCIAR AL SERVICIO DE GUARDAR DATOS EN LA SESSION
                            let value = {
                                cart_product: [
                                    create_array_for_product ('', '', 'all')
                                ]
                            }

                            set_var_session ('cart_object', value, 'cart_product', 'add').then (
                                cart_object_saved => {
                                    if (cart_object_saved.success) {
                                        location.href = `${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart`
                                    } else {
                                        toastr.error ('No se pudo almacenar la pre-reserva', 'Carrito de compra')
                                    }
                                }
                            )
                        }
                    } else {

                        create_shopping_cart (block_target, create_array_for_product ('product_type', '', 'show')).then (shopc => {
                            if (shopc.next) {
                                if (e.target.dataset.addtocart === 'true') {
                                    add_direct_product_to_cart (block_target, create_array_for_product ('dealer', '', 'show'), create_array_for_product ('category', '', 'show'), create_array_for_product ('pax', '', 'show'), create_array_for_product ('product_type', '', 'show'), create_array_for_product ('modality_code', '', 'show')).then (
                                        product_add => {
                                            if (product_add.next) {

                                                location.href = `${location.origin}/${sessionStorage.getItem ('locale')}/shopping_cart`
                                            } else {
                                                toastr.error ('No se pudo añadir el producto', 'Carrito de compra')
                                            }
                                        }
                                    )
                                }
                            }
                        })
                    }
                }
            }
        )
    }

    add_product_to_cart (e) {
        verify_product_in_cart ().then (
            verify => {
                if (verify.next) {

                    let element = e.target.dataset;
                    if (!element.hasOwnProperty ('id')) {
                        element = e.target.parentNode.dataset;
                    }
                    let amount = $ (`#input_price_${element.index}`);


                    toastr.info ('Añadiendo producto al carrito', 'Carrito de compra');

                    //CREANDO ID DEL PRODUCTO EN CARRITO DE COMPRA

                    create_array_for_product ('id', element.id);

                    //Actualizando los datos en la variable de session una vez añadido el producto
                    let value = {
                        cart_product: [
                            create_array_for_product ('', '', 'all')
                        ]
                    }

                    set_var_session ('cart_object', value, 'cart_product', 'add', element.index, false, false, true).then (
                        cart_object_saved => {
                            if (cart_object_saved.success) {
                                add_product_to_shopping_cart (`ibox_${element.index}`, parseInt (element.index), element.id, element.dealer, element.product, amount.val (), element.productType, element.hasModality).then (
                                    add_product => {


                                        if (add_product.next) {
                                            if (element.productType === 'car') {
                                                $ (`#add_product_${element.index}`)[0].classList.add ('disabled');
                                            }

                                            toastr.success ('Producto añadido correctamente', 'Carrito de compras');
                                            unBlockElementById (`ibox_${element.index}`);

                                        } else {
                                            toastr.error ('No se ha podido añadir el producto al carrito', 'Carrito de compras');
                                        }
                                    }
                                )
                            }
                        })



                }
            }
        )
    }

    remove_product_for_cart (e) {
        let element = e.target.dataset;
        if (!element.hasOwnProperty ('id')) {
            element = e.target.parentNode.dataset;
        }

        let value = {
            cart_product: [
                {
                    id: element.id
                }
            ]
        }

        if (element.item !== '0') {
            remove_item_to_shopping_cart (element.item).then (
                remove => {
                    if (remove.success) {
                        if (remove.items > 0) {
                            get_total_price ();
                            set_var_session ('cart_object', value, 'cart_product', 'erase_product', element.index).then (
                                remove_cart_object => {

                                    setTimeout(()=>{
                                        show_product ('card_product', true).then (
                                            show => {
                                                product_array = {};
                                            })
                                    },500)
                                }
                            )

                        } else {
                            cancel_to_shopping_cart ().then ();
                        }

                    }
                }
            )

        } else {
            set_var_session ('cart_object', value, 'cart_product', 'erase_product', element.index).then (
                remove_cart_object => {
                    show_product ('card_product', true).then (
                        show => {
                            product_array = {};
                            toastr.success ('Producto eliminado', 'Carrito de compra');
                        });
                }
            )

        }
    }

    set_merchant (e) {
        payment_type (e.target.dataset.merch);
    }

    get_cart_view_table (e) {
        let element = e.target.dataset;
        if (!element.hasOwnProperty ('id')) {
            element = e.target.parentNode.dataset;
        }
        checkSessionVarWithData ('cart_object').then (s_cart => {
            let products = s_cart.data.cart_product;
            get_shopping_cart_product (products, true, '_toolbar_table_resumen_shopping_cart', true).then ()
        })
    }

    cancel_shopping_cart () {
        cancel_to_shopping_cart ().then ();
    }


}